<template>
  <div class="goods-detail">
    <transition name="fade">
      <preLoading v-if="isLoading"></preLoading>
    </transition>
    <div class="mask"  v-if="showShareEWM" @click="showShareEWM = false">
      <div class="logo">
        <img style="width:10vw" :src="valueWorth" >
        <!-- <img style="width:10vw" src="../../../assets/images/log2.png" > -->
        <!-- 云楚新能源精选商城 -->
        <div>{{$globalConfig.logoTitle}}</div>
      </div >
      <div :style="{backgroundImage:'url('+goods.imgList[0]+')'}" class="ewm-bk">

      </div>
        <div class="er-wei-ma-text" @click.stop>
          ￥<span class="price">{{ (goods.skuDTOList[goods.skuDTOList.length-1].price /100).toFixed(2) }}</span>
          <br/>
          <span class="title">{{ goods.title}}</span>
         
       </div>
       <div class="er-wei-ma" @click.stop>
         <img class="er-wei-ma-img" width="100%" :src="ewmImg">
       </div>
    </div>
    <p class="backbox" :class="{ backFixed: isfixed }">
      <span class="iconfont icon-fanhui-copy back" @click="goBack"></span>
      <i>宝贝详情</i>
      <!-- <span
        class="iconfont icon-fenxiang-1"
        @click="showShareList = true"
        style="font-size: 0.38rem"
        v-if="is_weixn()"
      ></span> -->
        <span
        class="iconfont icon-fenxiang-1 back"
        @click="showShare"
        style="font-size: 0.38rem ; "
      ></span>
    </p>
    <!-- 轮播图 -->
   <div>
     <!-- <div class="tex">
     <img :src="headUrl" class="headUrl-img">
        <span class="tex-text">{{name}}刚刚购买了此产品</span>
      </div> -->
    <van-swipe :autoplay="0" @change="onChange">
       <van-swipe-item v-if="goods.videoUrl">
        <div class="video-bk">
          <video class="videourl"
           playsinline="true"
           controlsList='nofullscreen nodownload noremote footbar'
           muted
           autoplay
           controls
           loop
           width="100%" 
           :src="goods.videoUrl"
           ></video>
          </div>
      </van-swipe-item>
      <van-swipe-item v-for="(image, idx) in goods.imgList" :key="idx">
        <img    class="img" v-lazy="image" alt="商品图片" />
      </van-swipe-item>
      <div class="custom-indicator" slot="indicator" v-if="goods.videoUrl">
        {{ current + 1 }}/{{ swipeLength + 1 }}
      </div>
      <div class="custom-indicator" slot="indicator" v-else>
        {{ current + 1 }}/{{ swipeLength }}
      </div>
    </van-swipe>
    </div>
    <!-- 商品信息 -->
    <van-cell-group>
      <van-cell class="dark_bg">
        <div class="price">
          <!-- ￥{{ goods.price / 100 }} -->
          <!-- <span style="font-size: .24rem;">￥</span>{{ goods.vipPrice / 100 }} -->
          <span style="font-size: .24rem;">￥</span>{{ goods.showPrice}}
          <!-- <span
            style="
              color: #AEAEAE;
              font-size: 0.3rem;
              font-weight: 400;
              text-decoration: line-through;
            "
            v-if="goods.price"
            >￥{{ goods.price /100 }}</span
          > -->
        <div class="title dark_text">{{ goods.title }}</div>
        <div class="description">{{ goods.description }}</div>

        <div v-show="storeId" class="go-shop" @click="$router.push('/indexAlone')">进入店铺</div>

        </div>

      </van-cell>
      <van-cell class="express dark_bg">
        <!-- <van-col span="8" class="dark_text"
          >运费：￥{{ goods.transFee }}</van-col
        > -->
        <!-- <van-col span="8" class="dark_text" v-if="goods.sales > 0"
          >销量：{{ goods.sales }}</van-col
        > -->
        <van-col span="8" class="dark_text" v-if="goods.score"
          >积分：{{ goods.score }}</van-col
        >
      </van-cell>
    </van-cell-group>
    <!-- <div class='gap'></div> -->

    <!-- <div class="promisebox">
      <section class="promise">
        <span class="iconfont icon-baoxianchanpin"></span>
        <ul>
          <li style="font-size: 0.32rem">云楚新能源承诺</li>
          <li>云楚新能源所售商品严格把关，均为正品</li>
        </ul>
      </section>
    </div> -->

    <div class='gap'></div>

    <div class="parameter-select">
        <div class="parameter-center" @click='getFather'>
          <span class="parameter-left">选择</span>
          <span class="parameter-middle">规格</span>
          <img src="@/assets/images/more-title.png" style="width:.24rem;height:.26rem">
        </div>
        <div class="parameter-center" style="margin:.5rem 0">
          <span class="parameter-left">发货</span>
          <span class="parameter-middle">快递包邮</span>
        </div>
        <div class="parameter-center" @click="showParameter = true">
          <span class="parameter-left">参数</span>
          <span class="parameter-middle">参数详情</span>
          <img src="@/assets/images/more-title.png" style="width:.24rem;height:.26rem">
        </div>
    </div>
    <div class='gap'></div>


    <van-popup v-model="showParameter" class="parameter" closeable close-icon="close"  round position="bottom" :style="{ height: '30%' }" >
        <van-cell :title="item.attribute" v-for="item in goods.attributeList" :key="item.id">
          <template #right-icon>
            {{item.value}}
          </template>
        </van-cell>
    </van-popup>

    <!-- 商品评价入口 -->
    <van-cell
      :title="'商品评价(' + goods.appraisePage.total + ')'"
      to="comment"
      is-link
      v-if="goods.appraisePage ? goods.appraisePage.items.length : false"
      :border="false"
      class="dark_bg dark_text"
    />
    <van-cell
      title="商品评价"
      value="暂无评价，欢迎您购买之后留下宝贵的评价"
      class="no-comment dark_bg dark_text"
      v-else
      :border="false"
    />
    <!-- 商品视频 -->
    <div
      id="goods_video"
      :class="{ 'video-goods': goods.videoURL == '' }"
    ></div>
    <!-- 产品描述 -->
    <div v-html="goods.detail" class="goods_detailinfo"></div>
    <!-- 分享选择下拉窗 -->
    <van-popup
      v-model="showShareList"
      position="bottom"
      round
      class="share-popup"
      closeable
    >
      <ul class="share-list">
        <li @click="wxShareFriends('0')">
          <span
            class="iconfont icon-weixin-copy1"
            style="color: #03d510"
          ></span>
          <p>微信</p>
        </li>
        <li @click="wxShareFriends('1')">
          <span
            class="iconfont icon-pengyouquan-copy"
            style="color: #00df68"
          ></span>
          <p>朋友圈</p>
        </li>
      </ul>
    </van-popup>
  </div>
</template>

<script>
import Player from "xgplayer";
import { Swipe, SwipeItem, Cell, CellGroup, Col, Popup ,Toast} from "vant";

export default {
  data() {
    return {
      storeId:localStorage.storeId,
      goods: {},
      current: 0,
      count: 1,
      swipeLength: null,
      isLoading: true,
      accessToken: null,
      isfixed: false,
      backPath: null,
      showShareList: false,
      showShareEWM:false,
      deviceEnvir: this.Base.deviceEnvir(),
      player: null,
      changeCount: 0,
      img:'',
      name:undefined,
      headUrl:undefined,
      ewmImg:'',
      shareID:false,
      showParameter:false,
      valueWorth: "",
    };
  },
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Cell.name]: Cell,
    [Col.name]: Col,
    [CellGroup.name]: CellGroup,
    [Popup.name]: Popup,
  },

  
  // directives:{
  //   height(a,b){
  //     console.log(a,'a',b,'b');
  //     console.dir(a)
  //       console.log(a.offsetHeight);
  //   }
  // },
  created(){
      // this.logcode();
      // let logoLoadingLogoUrl = localStorage.getItem('logoLoadingLogoUrl')
      // if(logoLoadingLogoUrl) {
      //   this.valueWorth = logoLoadingLogoUrl
      // } else {
      //   this.getLoadingLogoUrl();
      // }
      this.getLoadingLogoUrl();
      console.log(this.storeId);
      this.getShareUserId()
  },
  mounted() {
    // 获取元素距离最初距离顶部的位置距离
    this.offsetTop = document.querySelector(".backbox").offsetTop;
    // 开启滚动监听
    window.addEventListener("scroll", this.handleScroll);

      // this.foo()
  },
 
  deactivated(){
    // console.log(9898);
    clearInterval(this.interval)
    this.showShareEWM = false
  },
 
  activated() {
    // isUseCache为false时才重新刷新获取数据
    this.logcode()
    
    if(localStorage.shareListID != this.$route.params.goodsId){
      localStorage.removeItem('shareList')
      localStorage.removeItem('shareID')
    }
    // 因为对list使用keep-alive来缓存组件，所以默认是会使用缓存数据的
    if (!this.$route.meta.isUseCache) {
      // 获取商品数据
      this.current = 0;
      this.isLoading = true;
      this.goods = {};
      this.getDetail();
      this.getOrderHistory();
    }
  },
  methods: {
    getLoadingLogoUrl() {
      this.$api.login
        .getConfigLogoUrl({
          k: "readLog",
        })
        .then((res) => {
          // 成功
          if (res.errno === 200) {
            this.valueWorth = res.data.valueWorth;
            localStorage.setItem('logoLoadingLogoUrl', this.valueWorth)
            // console.log(this.valueWorth);
          } else {
            this.$toast(res.errmsg);
          }
        });
    },
    getShareUserId() {
      // let testUrl = 'http://dhmall-app-v2.keplerlab.cn/#/goodsdetail/1247614/detail?openid=ovw1Ut50ovFOztXsNVMMZVP0shZc&s=1247614_151199_0_0_16'
      // console.log('截取用户id',testUrl.split('_')[1]);
      // console.log('window.location.href',window.location.href, shareUserId);
      // let shareUserId = window.location.href.split('_')[1]
      console.log('是否有&s=字符串', window.location.href.indexOf("&s="));
      if(window.location.href.indexOf("&s=") != -1 ) {
        let shareUserIdString = window.location.href.split('&s=')[1]
        let shareUserId = shareUserIdString.split('_')[1]
        console.log('截取用户id', shareUserId);
        if(shareUserId) {
          localStorage.setItem('shareUserId', shareUserId)
        }
      }
    },
    getFather(){
      console.log(123);
      this.$emit("openPopup") 
    },
    is_weixn() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return false;
      } else {
        return true;
      }
    },
    //生成分享二维码
    showShare(){
      if(!localStorage.token){
         Toast({
                   message: "登陆后才可以分享哦~",
                   duration: 1500,
                   forbidClick: true
                 });
                 return
      }
        let url = location.href;

      
       
          let text = url.split("goodsdetail/")[1];
          let spuId = text.split("/")[0];
          let arr ={}
      if(this.shareID || localStorage.shareID){
         arr = {
          spuId:spuId,
          // userId:localStorage.token,
          userId:localStorage.customerId,
          shareId:this.shareID || localStorage.shareID,
          storeId:localStorage.storeId
          }
      }else{
        arr = {
        spuId:spuId,
        // userId:localStorage.token,
          userId:localStorage.customerId,
        
        storeId:localStorage.storeId

        }
      }
      
      this.$api.shopcar.createQrCode(arr).then(res=>{
        console.log(res);
        this.ewmImg = res.data
      })

        this.showShareEWM = true
    },  
    logcode() {
     
      let url = location.href;
      if (!sessionStorage.openId) {

      
        if (url.indexOf("openid") != -1) {
          let text = url.split("?openid=")[1];
          let text1 = text.split("&")[0];
          let openId = text1;
          sessionStorage.openId = openId ? openId : sessionStorage.openId;
        }
      }

        if (url.indexOf("&s=") != -1) {
          let text = url.split("s=")[1];
          let shareList = text.split('_') 
          localStorage.shareList = shareList
          // console.log(shareList);
          //分享商品
          let oldGoodsId = shareList[0]
          //分享人
          let oldUserID = shareList[1]
          //是否分享
          let isShare = shareList[2]
          //分享id
          let shareId = shareList[3]
          localStorage.shareListID = oldGoodsId
          let storeId = shareList[4]
          console.log('storeID',storeId);
          if(storeId !== 'null'){
            console.log(1);
            localStorage.storeId = storeId
          }else{
            console.log(2);
            if(localStorage.storeId){
            console.log(3);

              localStorage.removeItem('storeId')
            }
          }

        }

        if(localStorage.shareList){

             if(!localStorage.token){
                // Toast({
                //    message: "系统未登录，请重新登录",
                //    duration: 1500,
                //    forbidClick: true
                //  });
                //  this.$router.replace({path: '/login',query: {redirect: this.$router.currentRoute.fullPath}})
                return
            }else{
               this.$api.order.verifyUser().then(res=>{
                  if(!res.id){
                      // Toast({
                      //   message: "系统未登录，请重新登录",
                      //   duration: 1500,
                      //   forbidClick: true
                      // });
                      // this.$router.replace({path: '/login',query: {redirect: this.$router.currentRoute.fullPath}})
                      return
                  }
                })

                

               let shareList = localStorage.shareList.split(',')
         
                //分享商品
                let oldGoodsId = shareList[0]
                //分享人
                let oldUserID = shareList[1]
                //是否分享
                let isShare = shareList[2]
                //分享id
                let shareId = shareList[3]
                let arr = {
                spuId :oldGoodsId,
                shareId:shareId,
                shareUserId:oldUserID,
                byShareUserId:localStorage.customerId
              }
              this.$api.shopcar.insert(arr).then(res=>{
                // console.log(res,898);
                this.shareID = res.data.id
                localStorage.shareID = this.shareID
              })
            }
        }

      
      


       
      
    },
    // 分享给朋友
    wxShareFriends(type) {
      var link = window.location.href;
      // 后台记录分享
      this.$server.shareApi
        .shareGoodsHomeAddress({
          accessToken: localStorage.getItem("token"),
          customerId: localStorage.getItem("customerId"),
          shareLink: link,
          goodsHomeId: this.goods.id,
          shareImage: this.goods.imageURL,
          shareContent: this.goods.name,
          weChatType: type,
        })
        .then((res) => {
          if (res.code === 100) {
            var paramsObj = {
              cmd: "012", // 方法名
              link, // 分享跳转地址
              imageUrl: this.goods.imageURL,
              name:
                res.returnValue.shareTitle ||
                "我正在云楚新能源上购买一个超级好货，你也来试试吧~", // 名字
              description: this.goods.name,
              type, // 类型  0聊天界面，1朋友圈，2收藏，3联系人
            };
            // IOS
            if (this.deviceEnvir === "IOS") {
              window.webkit.messageHandlers.invokeiOS.postMessage(paramsObj);
            } else if (this.deviceEnvir === "ANDROID") {
              window.android.invokeAndroid(JSON.stringify(paramsObj));
            }
          }
        });
    },
    // 视频播放初始化
    initVideo() {
      let that = this;
      if (!this.goods.videoURL) {
        return false;
      }
      let url =
          this.goods.videoURL || "//player.alicdn.com/video/aliyunmedia.mp4",
        len = this.goods.imageList.length,
        poster = this.goods.VCSUrl;
      this.player = new Player({
        id: "goods_video", // 播放器绑定元素
        url, // 视频地址
        poster, // 视频封面图
        width: "100%", // 播放器宽度
        fitVideoSize: "fixWidth", // 宽度固定高度自适应
        playsinline: true, // 内联模式
        "x5-video-player-type": "h5",
      });
    },
    gocomment() {
      this.$router.push("comment");
    },
    // 滚动监听  滚动触发的效果写在这里
    handleScroll() {
      // 获取滚动条距离顶部的位置
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条距离与元素顶部距离进行比较
      if (scrollTop > this.offsetTop) {
        this.isfixed = true;
      } else {
        this.isfixed = false;
      }
    },
    goBack() {
  // window.location.href =`http://192.168.31.86:8083/#/home`;//测试

      
      // console.log(this.$route);
      // this.$dialog.alert({
      //   message: '弹窗内容',
      //   theme: 'round-button',
      // }).then(() => {
      //   // on close
      // });

      this.$router.back(0);
    },
    onChange(index) {
      this.current = index;
    },
    // 购买弹幕
      getOrderHistory(){
      this.$api.shopcar.getOrderHistory().then((res) => {
        // console.log(res.data);
        let arr = res.data;
        var i = 0;
        this.name = arr[i].name;
        this.headUrl = arr[i].headUrl
        this.interval = setInterval(() =>{
          i++;
          // console.log(i);
          if(i == arr.length){
            i = 0;
          }
          this.name = arr[i].name
          this.headUrl = arr[i].headUrl
          // console.log(this.name);
        },7000)
      })
    },
    // 获取商品详情数据
    getDetail() {
      this.accessToken = localStorage.getItem("token");
      let companyId = this.$route.query.companyId;
      let requestAddress =
        this.$route.query.status == -1
          ? "loadGoodsHomeVOBoomAddress"
          : "goodsDetailAddress";
      if (this.$route.query.companyId == "undefined") companyId = "";
      this.$server[requestAddress]({
        accessToken: this.accessToken,
        spuId: this.$route.params.goodsId,
        companyId: companyId,
      }).then((res) => {
        if (res.data.stock == 0) {
          this.$toast("该商品暂无库存");
          setTimeout(() => {
            this.$router.back();
          }, 500);

          return;
        }
        if (res.errno === 200) {
          this.goods = res.data;
          this.swipeLength = this.goods.imgList.length;
          this.isLoading = false;
          // console.log(this.goods);
          // 发送给组件数据
          this.$root.eventHub.$emit("sendGoods", res.data);
          // 初始化视频
          this.initVideo();

         
              // for(let i =0 ; i< imgs.length ;i++){
              //   console.log(document.body.clientWidth);
              //   console.log(imgs[i].height);
              //   for(let j =0 ; j< imgs[i].ownerDocument.images.length ;j++){
              //       imgs.ownerDocument.images[j].height
              //   }
              //   if(i.clientHeight < document.body.clientWidth){
              //     // console.log(imgs[i]);
              //   }
              // }

         
//       for(var i = 0; i < imgs.length; i++ ){
//       console.log(2);

//      console.log(imgs[i].height)
//  }
        }
      });
    },
    foo(){
        this.$nextTick(()=>{
              var img = new Image()
              var imgs = document.querySelectorAll('.img');
              console.log(imgs);
              let arr = []
              for(let i =0; i<imgs.length ;i++){
                console.dir(imgs[i].currentSrc);
                  arr.push(imgs[i].currentSrc)
              }
              console.log(arr);
              img.src = arr[0]
              img.onload=()=>{
                console.log(img.height);
              }

     }
     )}
  },
  computed: {
    // 评价数量
    commentCount: function () {
      return Number(this.goods.commentCount);
    },
  },
  // 搜索页面的beforeRouteLeave钩子函数
  beforeRouteLeave(to, from, next) {
    if (to.name === "login" || to.name === "editaddress") {
      from.meta.isUseCache = true;
    } else if (this.goods.videoURL) {
      this.player.destroy(true);
    }
    next();
    localStorage.removeItem("randomNumber")

  },
};
</script>

<style lang='less' scoped>
@import "../../../assets/styles/element.less";
@red: #c83f3fb8;
.goods-detail {
  width: 100%;
  box-sizing: border-box;
  .backbox {
    width: 100%;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0);
    padding: 0.12rem /* 6/50 */ 0.32rem /* 16/50 */;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: space-between;
    font-size: 0.28rem /* 14/50 */;
    position: fixed;
    top: 0;
    left: 0;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
    z-index: 999;
    .back {
      top: 0.12rem /* 6/50 */;
      left: 0.32rem /* 16/50 */;
      color: #fff;
      width: 0.64rem /* 32/50 */;
      text-align: center;
      height: 0.64rem /* 32/50 */;
      line-height: 0.64rem /* 32/50 */;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 999;
      -webkit-transition: all 0.5s linear;
      transition: all 0.5s linear;
    }
    i {
      opacity: 0;
    }
    &.backFixed {
      background-color: #fff;
      -webkit-transition: all 0.5s linear;
      transition: all 0.5s linear;
      .back {
        -webkit-transition: all 0.5s linear;
        transition: all 0.5s linear;
        background-color: #fff;
        color: #ccc;
      }
      i {
        -webkit-transition: all 0.5s linear;
        transition: all 0.5s linear;
        opacity: 1;
        color: @baby_detail_title_text_color;
      }
    }
  }
  .van-swipe {
    position: relative;
    .van-swipe-item {
      img {
        width: 100%;
        display: block;
      }
    }
    .custom-indicator {
      padding: 0.06rem /* 3/50 */ 0.16rem /* 8/50 */;
      background-color: rgba(0, 0, 0, 0.3);
      color: #fff;
      font-size: 0.28rem /* 14/50 */;
      position: absolute;
      bottom: .5rem;
      right: 2%;
      text-align: center;
      border-radius: 0.32rem /* 16/50 */;
    }
  }
  .van-cell-group {
    margin-top: -.2rem;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
    overflow: hidden;
    .title {
      font-size: 0.34rem /* 13/50 */;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: .5rem;
    }
    .description{
        font-size: .26rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #AEAEAE;
        line-height: .5rem;
    }
    .price {
      font-size: .46rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: @price_text_color;
      line-height: .5rem;
    }
  }
  .promise {
    padding: 0.2rem /* 8/50 */ 0.24rem /* 12/50 */;
    .list(row);
    // background-color: #2e2e2e;
    background: linear-gradient(0deg, #333333, #1A1A1A);
    border-radius: .1rem;
    color: #fff;
    font-size: 0.28rem /* 11/50 */;

    align-items: center;
    & > span {
      color: #faf579;
      font-size: 0.64rem /* 32/50 */;
      margin-right: 0.32rem /* 16/50 */;
    }
    & > ul {
      li {
        line-height: 0.38rem /* 19/50 */;
        &:last-child {
          // margin-top: .16rem;
          font-size: 0.22rem /* 11/50 */;
          color: #CCCCCC;;
          line-height: .36rem;
        }
      }
    }
  }
  .no-comment {
    .van-cell__value {
      flex: 3;
      font-size: 0.22rem /* 11/50 */;
    }
  }
  .goods_detailinfo {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0 0.12rem /* 6/50 */ 0;
    font-size: 0.28rem /* 14/50 */;
    line-height: 0.48rem /* 24/50 */;
    & /deep/ img {
      width: 100%;
      height: 100%;
    }
  }
  .share-popup {
    .share-list {
      .list(row);
      box-sizing: border-box;
      padding: 0.76rem /* 38/50 */ 0.32rem /* 16/50 */ 0.32rem /* 16/50 */;
      li {
        flex: 1;
        font-size: 0.22rem /* 11/50 */;
        text-align: center;
        span {
          font-size: 0.64rem /* 32/50 */;
        }
        p {
          line-height: 0.44rem /* 22/50 */;
        }
      }
    }
  }
  /deep/ .prism-player {
    /deep/ .prism-big-play-btn {
      left: 50% !important;
      bottom: 50% !important;
      margin-left: -32px;
      margin-bottom: -32px;
    }
  }
  .videos {
    width: 100%;
    box-sizing: border-box;
  }
  .video-goods {
    height: 0 !important;
  }
}
.img{
  width:  100vw;
 vertical-align: middle;
}
.van-swipe-item{
  height: 91vw;
  // line-height: 40vh;
  overflow: hidden;
  // display: table-cell;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

 

}
.van-swipe{
  background-color: #fff;
}
.video-bk{
  background-color: #000;
  width: 100%;
  height: 100%;
  display: flex;
}
.tex{
  display: flex;
  // width: 4rem /* 200/50 */;
  height: .6rem /* 30/50 */;
  line-height: .6rem /* 30/50 */;
  box-sizing: border-box;
  // padding-left: .02rem /* 1/50 */;
  border-radius: 1.2rem /* 10/50 */;
  background: rgba(251, 78, 48, 0.503);
  color: #fff;
  position: absolute;
  font-size: .24rem /* 12/50 */;
  left: .2rem /* 10/50 */;
  z-index: 500;
  top: 5.8rem /* 10/50 */;
  animation: myfirst 7s infinite alternate;
  // text-align: center;
  .headUrl-img{
    margin-right: .1rem /* 5/50 */;
    width: .6rem /* 30/50 */;
    height: 100%;
  }
  .tex-text{
    display: block;
    margin-right: .2rem /* 10/50 */;
  }
}
.mask{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    // background-image: url(../../../assets/images/ewmbk.png) ;
   
    z-index: 99999;

      //修改省略号颜色
     text-overflow: ellipsis;
     color: #b8b8b8;
     .ewm-bk{
       margin-top: 25vw;
       height: 55%;
        background-repeat: no-repeat ;
        background-size: 100%;
        background-position: 0px 30%;
     }
      &::-webkit-scrollbar {
        width: 0;
      }
      .logo{
        position: fixed;
        top: .5rem;
        left: .2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 25vw;
        height: 10vw;
        font-size: .3rem;
        font-weight: 900;
      }
      .er-wei-ma{
        position: fixed;
        bottom: 7%;
        right: 0;
        // transform: translate(-50%);
        width: 35vw;
        height: 35vw;
        // background:url(../../../assets/images/ewmcard.png) no-repeat;
        background-size: 100%;
        .er-wei-ma-img{
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }

      }
       .er-wei-ma-text{
          position: fixed;
          bottom: 10%;
          left: 0;
          padding: 0 .8rem 0;
          width: 50vw;
          font-size: .32rem;
          
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;


        
          .price{
            color: @price_text_color;
            font-size: .8rem;
            font-weight: 1000;
             letter-spacing:-3px;
          }
          .title{
            color: #b8b8b8;
            font-size: .26rem;
          }
        }
}
@keyframes myfirst{
  0%{opacity: 0;}
  25%{opacity: 0.5;}
  30%{opacity: 0.8;}
  70%{opacity: 1;}
  75%{opacity: 0.5;}
  100%{opacity: 0;}
}
.go-shop{
  padding: .1rem 0;
  font-size: .32rem;
}
.gap{
  width: 100%;
  height: .2rem;
  background-color: #f6f6f9;
}
.promisebox{
  padding: .3rem;
  background-color: #fff;
}
.parameter{
  padding: 1rem .5rem;
  box-sizing: border-box;
}
.parameter-select{
  padding: .4rem .3rem;
  background-color: #fff;
  font-size: .26rem;
  .parameter-center{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .parameter-left{
      margin-right: .48rem;
      font-size: .26rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #AEAEAE;
      line-height: .5rem;
    }
    .parameter-middle{
      font-size: .26rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: .5rem;
      flex: 1;
    }
  }
}
</style>
